<template>
    <div class="collaboratives-spaces">
        <store-product 
            :data_products="data_products">
        </store-product>
        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Espacios Colaborativos',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Fabrica de muebles para recepciones en la ciudad de Bucaramanga. mas de 30 años de experiencia fabricando muebles para recepción' },
			{ name: 'keywords', content:'industrias pico, muebles oficina, muebles oficina Bucaramanga, muebles para oficina Bucaramanga, estantería metálica, estanterías metálicas, fabricas de estanterías metálicas, divisiones para oficina Bucaramanga, escritorios gerenciales Bucaramanga, escritorios para gerencia, Archivadores metálicos, fabrica de muebles para oficina, sillas para oficinas Bucaramanga, diseño de oficinas Bucaramanga, muebles para recepción Bucaramanga' },
			{ name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Espacios Colaborativos'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Espacios Colaborativos'},
			{name: 'twitter:description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Espacios Colaborativos'},
			{itemprop: 'description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    data() {
        return {
            data_products: {
                title_page: 'Espacios colaborativos',
                types: [
                    { text: 'Todos', value: 'todos' }
                ],
                products: [
                    {
                        name: 'Auditorio',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/espacios-colaborativos/productos/auditorio_1.jpg',
                        slides: [
                            'mobiliario-de-oficina/espacios-colaborativos/productos/auditorio_1.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    },
                    {
                        name: 'Cafetería',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/espacios-colaborativos/productos/cafeteria_1.jpg',
                        slides: [
                            'mobiliario-de-oficina/espacios-colaborativos/productos/cafeteria_1.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    },
                    {
                        name: 'Sala de descanso',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/espacios-colaborativos/productos/descanso_1.jpg',
                        slides: [
                            'mobiliario-de-oficina/espacios-colaborativos/productos/descanso_1.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    }
                ],
            }
        }
    }
}
</script>